import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {axiosClient} from "./axios/axiosClient";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import {logOut} from "./store/slices/authSlice";
import ScrollToTop from "./helpers/scrollToTop";
// should be in bottom, because of bootstrap css styles
import RoutesAll from "./routesAll";

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/
const UNAUTHORIZED = 401;
const {dispatch} = store;
axiosClient.interceptors.response.use(
    response => response,
    error => {
        const {status} = error.response;
        if (status === UNAUTHORIZED) {
            dispatch(logOut());
        }
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ScrollToTop/>
            <RoutesAll/>
            <ToastContainer
                position='bottom-center'
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='colored'
            />
        </Provider>
    </BrowserRouter>,
);
