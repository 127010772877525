import React from 'react';
import './loader.scss';

const Loader = ({fullScreen= false, withHeight = 'initial'}) => {
  return (
    <div className={`loader ${fullScreen && 'loader--full-screen'}`} style={{height: withHeight !== 'initial' ? withHeight + 'px' : 'initial'}}>
      <div className='lds-dual-ring'></div>
    </div>
  );
};

export default Loader;
