import React from 'react';
import './404.scss';

const NoPage = () => {
  return (
    <div className='page-404'>
      <h1 className='page-404__title'>404</h1>
    </div>
  );
};

export default NoPage;
