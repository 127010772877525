import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideSearch, showSearch } from 'store/slices/searchSlice';
import { CrossIcon, ZoomIcon } from 'assets';
import {searchProducts} from "store/slices/productsSlice";
import Loader from "../../../loader";

const HeaderSearch = () => {
  const { isSearchFocused, isResultShown, searchValue } = useSelector((state) => state.search);
  const [searchInputValue, setSearchInputValue] = useState('');

  const dispatch = useDispatch();
  const {searchProductsResult, searchProductsLoading} = useSelector((state) => state.products)

  useEffect(() => {
    setSearchInputValue(searchValue);
    if (searchValue) {
      dispatch(searchProducts({query: searchValue}))
    }
  }, [searchValue]);

  const onFocus = () => {
    dispatch(showSearch(searchInputValue));
  };
  const onBlur = () => {
    dispatch(hideSearch());
  };
  const handleInputSearchChange = (e) => {
    const value = e.target.value;
    setSearchInputValue(value);
    dispatch(showSearch(value));
  };
  const clearSearchInput = () => {
    setSearchInputValue('');
    onBlur();
  };

  return (
    <>
      <div className={`header-search ${isSearchFocused && 'header-search--focused'}`}>
        <label>
          <ZoomIcon className='header-search__icon' />
          <input
            placeholder='Найти товар'
            id='headerSearch'
            type='text'
            className='header-search__input'
            onChange={handleInputSearchChange}
            value={searchInputValue}
            onFocus={onFocus}
          />
          <button type='button' className='header-search__btn-close' onClick={clearSearchInput}>
            <CrossIcon />
          </button>
        </label>
        <button
          type='button'
          className='header-search__btn-close header-search__btn-close--mobile'
          onClick={clearSearchInput}
        >
          Отмена
        </button>
        <div
          className={`header-search__results ${isResultShown && 'header-search__results--show'}`}
        >
          <div className='header-search__results-content'>
            {
              searchProductsLoading === 'loading' ? <Loader/> : (
                  searchProductsResult && searchProductsResult.length > 0 ? (
                      <>
                        {
                          searchProductsResult.map(el => (
                              <Link
                                  to={`catalog/product/${el._id}`}
                                  className='header-search__results-link'
                                  onClick={clearSearchInput}
                                  key={el._id}
                              >
                                {el.name}
                              </Link>
                          ))
                        }
                        <Link
                            to={`catalog/search-results?q=${searchValue}`}
                            className='header-search__results-link-all'
                            onClick={clearSearchInput}
                        >
                          Показать все результаты поиска
                        </Link>
                      </>
                  ) : (
                      <p className="header-search__results-not-found">Ничего не найдено</p>
                  )
              )
            }
          </div>
        </div>
      </div>
      <div
        className={`header-search-container ${
          isSearchFocused ? 'header-search-container--active' : ''
        }`}
        onClick={onBlur}
      ></div>
    </>
  );
};

export default HeaderSearch;
