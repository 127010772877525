import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getUserOrders} from "store/slices/authSlice";
import ModalOrder from "pages/@components/modalOrder";
import Loader from "pages/@components/loader";
import {isoDateToNormal} from "helpers/isoDateToNormal";

const ProfileHistory = () => {
  const dispatch = useDispatch()
  const {userOrders, getUserOrdersLoading, user} = useSelector((state) => state.auth)

  useEffect(() => {
    dispatch(getUserOrders({id: user?.id, token: user?.token}))
  },[])

  const [modalShow, setModalShow] = useState(false);
  const [modalContent, setModalContent] = useState('')
  const openModal = (content) => {
      setModalShow(true)
      setModalContent(content)
  };



  return (
      <>
        <div className='profile-history'>
          <h4 className='profile-history__title'>История заказов</h4>
          {
            getUserOrdersLoading === 'loading' ? <Loader withHeight={300}/> : (
                userOrders.length > 0 ? (
                    <div className='profile-history__table-wrap'>
                        <table className='table profile-history__table'>
                            <thead className='profile-history__table-head'>
                            <tr>
                                <th scope='col'>№ Заказа</th>
                                <th scope='col'>Кол-во товаров</th>
                                <th scope='col'>Дата</th>
                                <th scope='col'>Покупатель</th>
                                <th scope='col'>Итого</th>
                                <th scope='col'></th>
                            </tr>
                            </thead>
                            <tbody className='profile-history__table-body'>
                            {
                                userOrders.length > 0 && userOrders.map(el => (
                                    <tr className='profile-history__table-item' key={el?._id}>
                                        <td>
                                            <p className="profile-history__table-item__cell--wrap">{el?._id}</p>
                                        </td>
                                        <td>
                                            <p>{el?.totalQuantity}</p>
                                        </td>
                                        <td>
                                            <p>{isoDateToNormal(el?.createdAt)}</p>
                                        </td>
                                        <td>
                                            <p>{el?.surname} {el?.name}</p>
                                        </td>
                                        <td>
                                            <p>{el?.totalPriceWithDiscount}c</p>
                                        </td>
                                        <td className='profile-history__table-item--details'>
                                            <button
                                                type='button'
                                                className='profile-history__table-item__btn btn-transitioned-animated'
                                                onClick={() => openModal(el)}
                                            >
                                                Детали заказа
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="profile-history__text">Пока заказов нет</p>
                )
            )
          }
        </div>
        <ModalOrder
            show={modalShow}
            onHide={() => setModalShow(false)}
            modalContent={modalContent}
        />
      </>
  );
};

export default ProfileHistory;
