import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CrossIcon } from 'assets';
import './modalPromotions.scss';

const ModalPromotions = ({ show, onHide, item }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='promotions-modal-dialog'
    >
      <Modal.Body className='promotions-modal__body'>
        <div className='promotions-modal__img'>
          <img src={item?.imageUrl} alt='Акция' />
        </div>
        <div className='promotions-modal__content'>
          <p className='promotions-modal__date'>{item?.redText}</p>
          <h3 className='promotions-modal__title'>{item?.title}</h3>
          <div className="promotions-modal__content-body" dangerouslySetInnerHTML={{__html: item?.body}}></div>
        </div>
        <button type='button' className='promotions-modal__btn-close' onClick={onHide}>
          <CrossIcon />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPromotions;
