import React from 'react';
import {useSelector} from "react-redux";
import Breadcrumbs from '../@components/breadcrumbs';
import TitleSection from '../@components/titleSection';
import Branches from '../@components/branches';
import {
  SocialsFaceBookIconBlack,
  SocialsInstagramIconBlack,
  SocialsWhatsAppIconBlack,
  ArrowIconDown, SocialsTiktokIconBlack,
} from 'assets';
import './contacts.scss';

const Contacts = () => {
  const {website} = useSelector((state) => state.website)

  return (
    <div className='contacts'>
      <div className='container'>
        <Breadcrumbs />
        <TitleSection redLine={false}>Контакты</TitleSection>
        <div className='contacts-banner'>
          <div className='contacts-banner__wrap'>
            <p className='contacts-banner__text'>
              Будем рады видеть вас у нас в офисе, ответить на любые вопросы, показать интересующий
              вас товар.{' '}
            </p>
            <div className='contacts-banner__right'>
              <div className='contacts-banner__socials'>
                <p>Мы в соц сетях:</p>
                <a href={`https://wa.me/${website?.whatsApp}`} target='_blank'>
                  <SocialsWhatsAppIconBlack />
                  What’s App
                </a>
                <a href={website?.instagram} target='_blank'>
                  <SocialsInstagramIconBlack />
                  Instagram
                </a>
                <a href={website?.facebook} target='_blank'>
                  <SocialsFaceBookIconBlack />
                  Facebook
                </a>
                <a href={website?.tiktok} target='_blank'>
                  <SocialsTiktokIconBlack />
                  Tiktok
                </a>
              </div>
              <a href='contacts#' target='_blank' className='contacts__link-download'>
                Скачать реквизиты
                <ArrowIconDown />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Branches showTitle={false} />
      <div className='contacts-bottom'>
        <div className='container'>
          <a href='contacts#' target='_blank' className='contacts__link-download'>
            Скачать реквизиты
            <ArrowIconDown />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
