import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSearchFocused: false,
  isResultShown: false,
  searchValue: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    showSearch: (state, action) => {
      state.isSearchFocused = true;
      state.searchValue = action.payload;
      state.isResultShown = state.searchValue.length > 0;
    },
    hideSearch: (state) => {
      state.isSearchFocused = false;
      state.isResultShown = false;
    },
  },
});

export const { showSearch, hideSearch } = searchSlice.actions;

export default searchSlice.reducer;
