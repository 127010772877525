import React, {useEffect, useState} from 'react';
import './pagination.scss'
import ReactPaginate from "react-paginate";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const Pagination = ({productsPagination}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [searchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(0)
    const handlePageClick = (event) => {
        const q = searchParams.get('q') ? `&q=${searchParams.get('q')}` : ''
        const page = `&page=${event.selected + 1}`
        const sort = searchParams.get('sort') ? `&sort=${searchParams.get('sort')}` : ''
        const search = q + page + sort
        navigate({
            search: search.replace(/&/, '?'),
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
    };

    useEffect(() => {
        setCurrentPage(searchParams.get('page') ? Number(searchParams.get('page') - 1) : 0)
    },[location])

    return (
        <div className="custom-pagination">
            <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                pageCount={productsPagination?.totalPages}
                renderOnZeroPageCount={null}
                forcePage={currentPage}
                nextLabel=">"
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
            />
        </div>
    );
};

export default Pagination;