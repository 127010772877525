import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {axiosClient} from "../../axios/axiosClient";
import axios from "axios";
const notify = (text) => toast(text);

const initialState = {
    promocodes: [],
    onePromocodes: null,
    loading: null,
    getAllPromocodesLoading: null,
    getOnePromocodesLoading: null,

    promocodeCart: null,
    checkPromocodesLoading: null,
};

export const getAllPromocodes = createAsyncThunk(
    'promocodes/getAllPromocodes',
    async function({token}, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/promocodes/get-all-promocodes`, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const getOnePromocodes = createAsyncThunk(
    'promocodes/getOnePromocodes',
    async function({id, token}, { rejectWithValue }) {
        try {
            const response = await axiosClient.get(`/promocodes/get-one-promocode/${id}`, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const createPromocodes = createAsyncThunk(
    'promocodes/createPromocodes',
    async function ({token, desc, code, discountMinOrderValue, discountType, discountPercent, discountStatic, discountQuantity, expiryDateType, expiryDateValue}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post(`/promocodes/create`,{desc, code, discountMinOrderValue, discountType, discountPercent, discountStatic, discountQuantity, expiryDateType, expiryDateValue},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const updatePromocodes = createAsyncThunk(
    'promocodes/updatePromocodes',
    async function ({token, id, desc, code, discountMinOrderValue, discountType, discountPercent, discountStatic, discountQuantity, expiryDateType, expiryDateValue}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.put(`/promocodes/update/${id}`,{token, desc, code, discountMinOrderValue, discountType, discountPercent, discountStatic, discountQuantity, expiryDateType, expiryDateValue},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const deletePromocodes = createAsyncThunk(
    'promocodes/deletePromocodes',
    async function ({id, token, imagePublicId}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.delete(`/promocodes/delete/${id}`,{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const checkPromocodes = createAsyncThunk(
    'promocodes/checkPromocodes',
    async function ({code, totalPrice}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post('/promocodes/check', {code, totalPrice})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);



const promocodesSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearPromocodeCart: (state) => {
            state.promocodeCart = null
        }
    },
    extraReducers: {
        [getAllPromocodes.pending]: (state) => {
            state.getAllPromocodesLoading = 'loading';
        },
        [getAllPromocodes.fulfilled]: (state, action) => {
            state.getAllPromocodesLoading = 'success'
            state.promocodes = action.payload
        },
        [getAllPromocodes.rejected]: (state, action) => {
            state.getAllPromocodesLoading = 'error'
            notify(action.payload)
        },

        [getOnePromocodes.pending]: (state) => {
            state.getOnePromocodesLoading = 'loading'
            state.onePromocodes = null
        },
        [getOnePromocodes.fulfilled]: (state, action) => {
            state.getOnePromocodesLoading = 'success'
            state.onePromocodes = action.payload
        },
        [getOnePromocodes.rejected]: (state, action) => {
            state.getOnePromocodesLoading = 'error'
            notify(action.payload)
        },

        [createPromocodes.pending]: (state) => {
            state.loading = 'loading';
        },
        [createPromocodes.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.promocodes = [action.payload, ...state.promocodes]
            notify('Промокод создан!')
        },
        [createPromocodes.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [updatePromocodes.pending]: (state) => {
            state.loading = 'loading';
        },
        [updatePromocodes.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.promocodes = state.promocodes.map(el => {
                if (el._id === action.payload._id) {
                    return {...el, ...action.payload}
                } else {
                    return el
                }
            })
            notify('Промокод обновлен!')
        },
        [updatePromocodes.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [deletePromocodes.pending]: (state) => {
            state.loading = 'loading';
        },
        [deletePromocodes.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.promocodes = state.promocodes.filter(el => el._id !== action.payload._id)
            notify('Промокод удалён!')
        },
        [deletePromocodes.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [checkPromocodes.pending]: (state) => {
            state.checkPromocodesLoading = 'loading';
        },
        [checkPromocodes.fulfilled]: (state, action) => {
            state.checkPromocodesLoading = 'success'
            state.promocodeCart = action.payload
            notify('Промокод задействован!')
        },
        [checkPromocodes.rejected]: (state, action) => {
            state.checkPromocodesLoading = 'error'
            notify(action.payload)
        },
    }
});

export const {clearPromocodeCart} = promocodesSlice.actions;

export default promocodesSlice.reducer;
