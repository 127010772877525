import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getOneOrders} from "store/slices/ordersSlice";

import './orderDetails.scss'
import Loader from "../@components/loader";
import {ProductPlaceholderSmall} from "../../assets";
import TitleSection from "../@components/titleSection";
import {isoDateToNormal} from "../../helpers/isoDateToNormal";

const OrderDetails = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const {oneOrders, getOneOrdersLoading} = useSelector((state) => state.orders)
    useEffect(() => {
        dispatch(getOneOrders({id}))
    },[])
    return (
        <div className="container order-details">
            {
                getOneOrdersLoading === 'loading' ? (
                    <Loader withHeight={300}/>
                ) : (
                    oneOrders && (
                        <>
                            <TitleSection redLine={false}>Спасибо за ваш заказ!</TitleSection>
                            <div className='modal-order__details mt-4' style={{maxWidth: '400px'}}>
                                <h4 className='modal-order__title'>Детали заказа</h4>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>ФИО</p>
                                    <span>{oneOrders?.surname} {oneOrders?.name}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>E-mail</p>
                                    <span>{oneOrders?.email}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Я</p>
                                    <span>{oneOrders?.person}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Способ получениия</p>
                                    <span>{oneOrders?.shipment}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Дата заказа</p>
                                    <span>{isoDateToNormal(oneOrders?.createdAt)}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Адрес доставки</p>
                                    <span>{oneOrders?.country}, {oneOrders?.city}, {oneOrders?.street}, {oneOrders?.house}{oneOrders?.apartment && `, ${oneOrders?.apartment}`}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Получатель</p>
                                    <span>{oneOrders?.surname} {oneOrders?.name}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Телефон получателя</p>
                                    <span>{oneOrders?.phone}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Доп. телефон/whatsapp</p>
                                    <span>{oneOrders?.whatsapp}</span>
                                </div>
                                <div className='modal-order__details-item modal-order__text'>
                                    <p>Комментарий</p>
                                    <span>{oneOrders?.comment}</span>
                                </div>
                            </div>
                            <div className="modal-order__table-container">
                                <table className='table modal-order__table'>
                                    <tbody className='modal-order__table-body'>
                                    {oneOrders?.products && oneOrders?.products.map((el) => (
                                        <tr className='modal-order__table-item' key={el?._id}>
                                            <td>
                                                <div className='modal-order__table-item__img'>
                                                    <img src={el?.imagePreview ? el?.imagePreview : ProductPlaceholderSmall} alt='Продукт' />
                                                </div>
                                            </td>
                                            <td>
                                                <p className='modal-order__table-item__title'>{el?.name}</p>
                                            </td>
                                            <td>
                                                <div className='modal-order__table-item__count'>
                                                    <p>Кол-во</p>
                                                    <span>{el?.cartQuantity}</span>
                                                </div>
                                            </td>

                                            <td>
                                                <div className='modal-order__table-item__code'>
                                                    <p>Артикул</p>
                                                    <span>{el?.sku}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='modal-order__table-item__price'>
                                                    {el?.oldPrice ? (
                                                        <div className='modal-order__table-item__price-discount'>
                                                            <p>{el?.price}c</p>
                                                            <span>{el?.oldPrice}c</span>
                                                        </div>
                                                    ) : (
                                                        <p>{el?.price}c</p>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className='modal-order__total mb-4'>
                                <div className='modal-order__total-item modal-order__total-item--ship'>
                                    <p>Доставка</p>
                                    <p>Бесплатно</p>
                                </div>
                                <div className='modal-order__total-item modal-order__total-item--ship'>
                                    <p>Товары({oneOrders?.totalQuantity})</p>
                                    <p>{oneOrders?.totalPrice}с</p>
                                </div>
                                {
                                    oneOrders?.promocode && (
                                        <div className='modal-order__total-item modal-order__total-item--ship'>
                                            <p>Промокод</p>
                                            <p>{oneOrders?.promocode?.code} ({oneOrders?.totalPrice - oneOrders?.totalPriceWithDiscount}с)</p>
                                        </div>
                                    )
                                }
                                <div className='modal-order__total-item modal-order__total-item--price'>
                                    <p>Итого</p>
                                    <p>{oneOrders?.totalPriceWithDiscount}c</p>
                                </div>
                            </div>
                        </>
                    )
                )
            }
        </div>
    );
};

export default OrderDetails;