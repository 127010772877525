import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {axiosClient} from "../../axios/axiosClient";
import axios from "axios";
const notify = (text) => toast(text);

const initialState = {
    sales: [],
    oneSales: null,
    loading: null,
    getAllSalesLoading: null,
    getOneSalesLoading: null
};

export const getAllSales = createAsyncThunk(
    'sales/getAllSales',
    async function(_, { rejectWithValue }) {
        try {
            const response = await axiosClient.post(`/sales/get-all-sales`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const getOneSales = createAsyncThunk(
    'sales/getOneSales',
    async function({id}, { rejectWithValue }) {
        try {
            const response = await axiosClient.post(`/sales/get-one-sale/${id}`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const createSales = createAsyncThunk(
    'sales/createSales',
    async function ({token, redText, title, body, image}, { rejectWithValue  }) {
        try {
            let imageUrl = "";
            let imagePublicId = "";
            try {
                if (image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/sales/generate-sale-photo-signature', {}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        imageUrl = dataRes.data.eager[0].url;
                    } else {
                        imageUrl = dataRes.data.url
                    }
                    imagePublicId =  /[^/]*$/.exec(dataRes.data.public_id)[0]
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }

            const response = await axiosClient.post(`/sales/create`,{redText, title, body, imageUrl, imagePublicId},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const updateSales = createAsyncThunk(
    'sales/updateSales',
    async function ({token, redText, title, body, id, imagePublicId, image}, { rejectWithValue  }) {
        try {
            let imageUrl = "";
            try {
                if (image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/sales/generate-sale-photo-signature', {imagePublicId}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    formData.append("public_id", imagePublicId);
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        imageUrl = dataRes.data.eager[0].url;
                    } else {
                        imageUrl = dataRes.data.url
                    }
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }

            const response = await axiosClient.put(`/sales/update/${id}`,{redText, title, body, imageUrl},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const deleteSales = createAsyncThunk(
    'sales/deleteSales',
    async function ({id, token, imagePublicId}, { rejectWithValue  }) {
        try {
            try {
                await axiosClient.delete(`/sales/delete-sale-photo/${imagePublicId}`, {headers: { 'Authorization': `Bearer ${token}` }})
            } catch (err) {
                return rejectWithValue('Не удалось удалить фото акции');
            }

            const response = await axiosClient.delete(`/sales/delete/${id}`,{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);



const salesSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {},
    extraReducers: {
        [getAllSales.pending]: (state) => {
            state.getAllSalesLoading = 'loading';
        },
        [getAllSales.fulfilled]: (state, action) => {
            state.getAllSalesLoading = 'success'
            state.sales = action.payload
        },
        [getAllSales.rejected]: (state, action) => {
            state.getAllSalesLoading = 'error'
            notify(action.payload)
        },

        [getOneSales.pending]: (state) => {
            state.getOneSalesLoading = 'loading'
            state.oneSales = null
        },
        [getOneSales.fulfilled]: (state, action) => {
            state.getOneSalesLoading = 'success'
            state.oneSales = action.payload
        },
        [getOneSales.rejected]: (state, action) => {
            state.getOneSalesLoading = 'error'
            notify(action.payload)
        },

        [createSales.pending]: (state) => {
            state.loading = 'loading';
        },
        [createSales.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.sales = [action.payload, ...state.sales]
            notify('Акция создана!')
        },
        [createSales.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [updateSales.pending]: (state) => {
            state.loading = 'loading';
        },
        [updateSales.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.oneSales = action.payload
            notify('Акция обновлена!')
        },
        [updateSales.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [deleteSales.pending]: (state) => {
            state.loading = 'loading';
        },
        [deleteSales.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.sales = state.sales.filter(el => el._id !== action.payload._id)
            notify('Акция удалена!')
        },
        [deleteSales.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },
    }
});

export const {} = salesSlice.actions;

export default salesSlice.reducer;
