import React from 'react';
import { Link } from 'react-router-dom';
import {ArrowIconRed, ArrowIconCircleSmall} from 'assets';
import './newsCard.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const NewsCard = ({ item, iconCircle = false }) => {
    return (
        <Link to={`/news/${item?._id}`} className='news__card'>
            <div className="news__card-img">
                <LazyLoadImage
                    src={item?.imageUrl}
                    width={296}
                    height={221}
                    alt="Превью новостей"
                    effect="opacity"
                />
            </div>
            <div className='news__card-bottom'>
                <p className='news__card-text'>{item?.title}</p>
                <p className='news__card-link'>
                    Читать больше
                    {iconCircle ? <ArrowIconCircleSmall /> : <ArrowIconRed />}
                </p>
            </div>
        </Link>
    );
};

export default NewsCard;
