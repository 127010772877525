import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosClient} from "../../axios/axiosClient";
import { toast } from 'react-toastify';
import axios from "axios";
const notify = (text) => toast(text);
const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    user: user ? user : null,
    isLoggedIn: !!user,
    loginLoading: null,
    signupLoading: null,

    modalAuthShow: {type: 'signup', status: false},
    modalAuthLoading: null,

    activationLoading: null,

    validateChangePasswordTokenLoading: null,
    changePasswordLoading: null,

    changeEmailLoading: null,
    cancelChangeEmailLoading: null,

    editUserLoading: null,

    userOrders: [],
    getUserOrdersLoading: null
};

export const login = createAsyncThunk(
    'auth/login',
    async function ({email, password}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post('/user/login', {email, password})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const signup = createAsyncThunk(
    'auth/signup',
    async function ({email, password, name, surname, phone}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post('/user/signup', {email, password, name, surname, phone})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const editUser = createAsyncThunk(
    'auth/editUser',
    async function ({email, name, surname, phone, id, toggleEditProfile, image, token}, { rejectWithValue  }) {
        try {
            let imageUrl = "";
            try {
                if (image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/user/generate-avatar-signature', {id}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    formData.append("public_id", id);
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        imageUrl = dataRes.data.eager[0].url;
                    } else {
                        imageUrl = dataRes.data.url
                    }
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }
            const response = await axiosClient.put('/user/update', {email, name, surname, phone, id, imageUrl}, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                toggleEditProfile()
                return response?.data
            }
        } catch (error) {
            return rejectWithValue({status: error?.response?.status, message: error?.response?.data?.error || error?.message});
        }
    },
);

export const resendActivationLink = createAsyncThunk(
    'auth/resendActivationLink',
    async function (_, { rejectWithValue, getState  }) {
        const {auth} = getState()
        const _id = auth?.user?.id
        const email = auth?.user?.email
        try {
            const response = await axiosClient.post('user/resend-activation-link', {_id, email})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const activateAccount = createAsyncThunk(
    'auth/activateAccount',
    async function ({token}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post('user/activate-account', {token})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const sendPasswordRecoveryLink = createAsyncThunk(
    'auth/sendPasswordRecoveryLink',
    async function ({email}, { rejectWithValue}) {
        try {
            const response = await axiosClient.post('user/send-password-recovery-link', {email})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const validateChangePasswordToken = createAsyncThunk(
    'auth/validateChangePasswordToken',
    async function ({token}, { rejectWithValue}) {
        try {
            const response = await axiosClient.post('user/validate-change-password-token', {token})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
)

export const changePassword = createAsyncThunk(
    'auth/changePassword',
    async function ({token, password}, { rejectWithValue}) {
        try {
            const response = await axiosClient.post('user/change-password', {token, password})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
)

export const changeEmail = createAsyncThunk(
    'auth/changeEmail',
    async function({token}, { rejectWithValue }) {
        try {
            const response = await axiosClient.post('user/change-email', {token})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
)

export const cancelChangeEmail = createAsyncThunk(
    'auth/cancelChangeEmail',
    async function({token, id}, { rejectWithValue }) {
        try {
            const response = await axiosClient.post('user/cancel-change-email', {id}, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
)

export const getUserOrders = createAsyncThunk(
    'auth/getUserOrders',
    async function({token, id}, { rejectWithValue }) {
        try {
            const response = await axiosClient.post('user/get-orders', {id}, {headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
)


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logOut: (state) => {
            state.isLoggedIn = false
            state.user = null
            localStorage.removeItem('user')
        },
        toggleModalAuth: (state, action) => {
            state.modalAuthShow = {type: action.payload.type, status: action.payload.status}
        },
        closeModalAuth: (state) => {
            state.modalAuthShow = {...state.modalAuthShow, status: false}
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.loginLoading = 'loading';
        },
        [login.fulfilled]: (state, action) => {
            state.loginLoading = 'success';
            if (action.payload?.isActivated) {
                state.isLoggedIn = true;
                state.user = action.payload;
                localStorage.setItem('user', JSON.stringify(action.payload))
            } else {
                state.user = action.payload;
                state.modalAuthShow = {type: 'login', status: true}
            }
        },
        [login.rejected]: (state, action) => {
            state.loginLoading = 'error';
            notify(action.payload)
        },

        [signup.pending]: (state) => {
            state.signupLoading = 'loading';
        },
        [signup.fulfilled]: (state) => {
            state.signupLoading = 'success';
            state.modalAuthShow = {type: 'signup', status: true}
        },
        [signup.rejected]: (state, action) => {
            state.signupLoading = 'error';
            notify(action.payload)
        },

        [resendActivationLink.pending]: (state) => {
            state.modalAuthLoading = 'loading'
        },
        [resendActivationLink.fulfilled]: (state) => {
            state.modalAuthLoading = 'success'
            state.modalAuthShow = {...state.modalAuthShow, status: false}
            notify('Письмо успешно отправлено')
        },
        [resendActivationLink.rejected]: (state) => {
            state.modalAuthLoading = 'error'
            notify('Ошибка при отправке письма. Попробуйте ещё раз.')
        },

        [activateAccount.pending]: (state) => {
            state.activationLoading = 'loading'
        },
        [activateAccount.fulfilled]: (state) => {
            state.activationLoading = 'success'
            notify('Аккаунт успешно активирован')
        },
        [activateAccount.rejected]: (state) => {
            state.activationLoading = 'error'
            notify('Ссылка недействительна')
        },

        [sendPasswordRecoveryLink.pending]: (state) => {
            state.modalAuthLoading = 'loading'
        },
        [sendPasswordRecoveryLink.fulfilled]: (state) => {
            state.modalAuthLoading = 'success'
            state.modalAuthShow = {...state.modalAuthShow, status: false}
            notify('Письмо успешно отправлено')
        },
        [sendPasswordRecoveryLink.rejected]: (state, action) => {
            state.modalAuthLoading = 'error'
            notify(action.payload)
        },

        [validateChangePasswordToken.pending]: (state) => {
            state.validateChangePasswordTokenLoading = 'loading'
        },
        [validateChangePasswordToken.fulfilled]: (state) => {
            state.validateChangePasswordTokenLoading = 'success'
        },
        [validateChangePasswordToken.rejected]: (state) => {
            state.validateChangePasswordTokenLoading = 'error'
            notify('Ссылка недействительна')
        },

        [changePassword.pending]: (state) => {
            state.changePasswordLoading = 'loading'
        },
        [changePassword.fulfilled]: (state) => {
            state.changePasswordLoading = 'success'
            notify('Пароль был успешно изменён!')
        },
        [changePassword.rejected]: (state, action) => {
            state.changePasswordLoading = 'error'
            notify(action.payload)
        },

        [changeEmail.pending]: (state) => {
            state.changeEmailLoading = 'loading'
        },
        [changeEmail.fulfilled]: (state) => {
            state.changeEmailLoading = 'success'
            notify('Ваша почта была успешна изменена, войдите использую новую почту!')
            state.isLoggedIn = false
            state.user = null
            localStorage.removeItem('user')
        },
        [changeEmail.rejected]: (state, action) => {
            state.changeEmailLoading = 'error'
            notify(action.payload)
        },

        [cancelChangeEmail.pending]: (state) => {
            state.cancelChangeEmailLoading = 'loading'
        },
        [cancelChangeEmail.fulfilled]: (state, action) => {
            state.cancelChangeEmailLoading = 'success'
            state.user = {...state.user, ...action.payload}
            localStorage.setItem('user', JSON.stringify({...state.user, ...action.payload}))
            notify('Вы успешно отменили изменение почты!')
        },
        [cancelChangeEmail.rejected]: (state, action) => {
            state.cancelChangeEmailLoading = 'error'
            notify(action.payload)
        },

        [editUser.pending]: (state) => {
            state.editUserLoading = 'loading';
        },
        [editUser.fulfilled]: (state, action) => {
            state.editUserLoading = 'success';
            state.user = {...state.user, ...action.payload}
            localStorage.setItem('user', JSON.stringify({...state.user, ...action.payload}))
            notify('Профиль обновлён!')
        },
        [editUser.rejected]: (state, action) => {
            state.editUserLoading = 'error';
            if (action.payload?.status === 401) {
                notify(action.payload?.message)
                state.isLoggedIn = false
                state.user = null
                localStorage.removeItem('user')
            } else {
                notify(action.payload.message)
            }
        },

        [getUserOrders.pending]: (state) => {
            state.getUserOrdersLoading = 'loading';
        },
        [getUserOrders.fulfilled]: (state, action) => {
            state.getUserOrdersLoading = 'success';
            state.userOrders = action?.payload?.orders.reverse()
        },
        [getUserOrders.rejected]: (state) => {
            state.getUserOrdersLoading = 'error';
        },
    }
});

export const {logOut, toggleModalAuth, closeModalAuth} = authSlice.actions;

export default authSlice.reducer;
