import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {signup} from "store/slices/authSlice";
import Form from "react-bootstrap/Form";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Loader from "../../@components/loader";
import {EyeIconHide, EyeIconShow} from "assets";
import '../auth.scss'


const Signup = () => {
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false)
    const dispatch = useDispatch()

    const togglePasswordShow = () => setShowPass(!showPass)

    const {signupLoading} = useSelector((state) => state.auth)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (signupLoading !== 'loading') {
            dispatch(signup({email, password, name, surname, phone}))
        }
    }
    return (
        <div className="container">
            <Form className="auth-form" onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formBasicName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите имя"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            required={true}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicSurname">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Введите фамилию"
                            onChange={(e) => setSurname(e.target.value)}
                            value={surname}
                            required={true}
                        />
                    </Form.Group>
                </Row>
                <Form.Group as={Col} className="mb-3" controlId="formBasicPhone">
                    <Form.Label>Номер телефона</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Введите номер"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required={true}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Введите e-mail"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required={true}
                    />
                </Form.Group>
                <Form.Group className="auth-form__password" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        type={showPass ? 'text' : 'password'}
                        placeholder="Введите пароль"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required={true}
                    />
                    <button type="button" className="auth-form__password-btn" onClick={togglePasswordShow}>{showPass ? <EyeIconShow/> : <EyeIconHide/>}</button>
                </Form.Group>
                <Form.Text id="passwordHelpBlock" muted className="mb-3 d-inline-block auth-form__help">
                    <p className="mt-2 mb-1">Латиница</p>
                    <p className="mb-1">Минимум 8 символов</p>
                    <p>Минимум 1 цифра</p>
                </Form.Text>
                <button type="submit" className="auth-form__btn-submit">
                    Зарегистрироваться
                </button>
                <div className="auth-form__bottom">
                    <p>Есть аккаунт? <Link to="/login">Войдите</Link></p>
                </div>
            </Form>
            {signupLoading === 'loading' && <Loader fullScreen={true}/>}
        </div>
    );
};

export default Signup;