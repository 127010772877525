import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {axiosClient} from "../../axios/axiosClient";
import axios from "axios";
const notify = (text) => toast(text);

const initialState = {
    news: [],
    sortedNews: [],
    oneNews: null,
    loading: null,
    getAllNewsLoading: null,
    getOneNewsLoading: null
};

export const getAllNews = createAsyncThunk(
    'news/getAllNews',
    async function(_, { rejectWithValue }) {
        try {
            const response = await axiosClient.post(`/posts/get-all-posts`)
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const getOneNews = createAsyncThunk(
    'news/getOneNews',
    async function({id, shorten = false}, { rejectWithValue }) {
        try {
            if (shorten) {
                const response = await axiosClient.post(`/posts/get-one-post-shorten/${id}`)
                if (response?.data) {
                    return response?.data
                }
            } else {
                const response = await axiosClient.post(`/posts/get-one-post/${id}`)
                if (response?.data) {
                    return response?.data
                }
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    }
);
export const createNews = createAsyncThunk(
    'news/createNews',
    async function ({token, title, body, image}, { rejectWithValue  }) {
        try {
            let imageUrl = "";
            let imagePublicId = "";
            try {
                if (image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/posts/generate-post-photo-signature', {}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        imageUrl = dataRes.data.eager[0].url;
                    } else {
                        imageUrl = dataRes.data.url
                    }
                    imagePublicId =  /[^/]*$/.exec(dataRes.data.public_id)[0]
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }

            const response = await axiosClient.post(`/posts/create`,{title, body, imageUrl, imagePublicId},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const updateNews = createAsyncThunk(
    'news/updateNews',
    async function ({token, title, body, id, imagePublicId, image}, { rejectWithValue  }) {
        try {
            let imageUrl = "";
            try {
                if (image) {
                    const cloudinaryConfigResponse = await axiosClient.post('/posts/generate-post-photo-signature', {imagePublicId}, {headers: { 'Authorization': `Bearer ${token}` }})
                    const cloudinaryConfig = cloudinaryConfigResponse.data
                    const formData = new FormData();
                    formData.append("file", image);
                    formData.append("upload_preset", cloudinaryConfig.uploadPreset);
                    formData.append("eager", cloudinaryConfig.eager);
                    formData.append("api_key", cloudinaryConfig.apiKey);
                    formData.append("timestamp", cloudinaryConfig.timestamp);
                    formData.append("signature", cloudinaryConfig.signature);
                    formData.append("public_id", imagePublicId);
                    const dataRes = await axios.post(
                        `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloudName}/image/upload`,
                        formData
                    );
                    if (dataRes.data.eager[0].url) {
                        imageUrl = dataRes.data.eager[0].url;
                    } else {
                        imageUrl = dataRes.data.url
                    }
                }
            } catch (err) {
                return rejectWithValue('Ошибка загрузки изображения');
            }

            const response = await axiosClient.put(`/posts/update/${id}`,{title, body, imageUrl},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);
export const deleteNews = createAsyncThunk(
    'news/deleteNews',
    async function ({id, token, imagePublicId}, { rejectWithValue  }) {
        try {
            try {
                await axiosClient.delete(`/posts/delete-post-photo/${imagePublicId}`, {headers: { 'Authorization': `Bearer ${token}` }})
            } catch (err) {
                return rejectWithValue('Не удалось удалить фото новости');
            }

            const response = await axiosClient.delete(`/posts/delete/${id}`,{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

export const saveNewsPopularity = createAsyncThunk(
    'news/saveNewsPopularity',
    async function ({token, arrayData}, { rejectWithValue  }) {
        try {
            const response = await axiosClient.post(`/posts/save-popularity`,{arrayData},{headers: { 'Authorization': `Bearer ${token}` }})
            if (response?.data) {
                return response?.data
            }
        } catch (error) {
            return rejectWithValue(error.response.data.error || error.message);
        }
    },
);

const sortNullishValues = (arr = []) => {
    const assignValue = val => {
        if (val === null) {
            return Infinity;
        } else {
            return val;
        }
    }
    const sorter = (a, b) => {
        return assignValue(a.popularity) - assignValue(b.popularity);
    };
    return arr.sort(sorter);
}


const newsSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        getSortedNews:(state, action) => {
            if(action.payload === 'Популярные') {
                state.sortedNews = sortNullishValues([...state.news])
            } else {
                state.sortedNews = [...state.news]
            }
            state.getAllNewsLoading = 'loading'
        },
        removeLoading:(state) => {
            state.getAllNewsLoading = null
        }
    },
    extraReducers: {
        [getAllNews.pending]: (state) => {
            state.getAllNewsLoading = 'loading';
        },
        [getAllNews.fulfilled]: (state, action) => {
            state.getAllNewsLoading = 'success'
            state.news = action.payload
            state.sortedNews = action.payload
        },
        [getAllNews.rejected]: (state, action) => {
            state.getAllNewsLoading = 'error'
            notify(action.payload)
        },

        [getOneNews.pending]: (state) => {
            state.getOneNewsLoading = 'loading'
            state.oneNews = null
        },
        [getOneNews.fulfilled]: (state, action) => {
            state.getOneNewsLoading = 'success'
            state.oneNews = action.payload
        },
        [getOneNews.rejected]: (state, action) => {
            state.getOneNewsLoading = 'error'
            notify(action.payload)
        },

        [createNews.pending]: (state) => {
            state.loading = 'loading';
        },
        [createNews.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.news = [action.payload, ...state.news]
            notify('Новость создана!')
        },
        [createNews.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [updateNews.pending]: (state) => {
            state.loading = 'loading';
        },
        [updateNews.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.oneNews = action.payload
            notify('Новость обновлена!')
        },
        [updateNews.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [deleteNews.pending]: (state) => {
            state.loading = 'loading';
        },
        [deleteNews.fulfilled]: (state, action) => {
            state.loading = 'success'
            state.news = state.news.filter(el => el._id !== action.payload._id)
            notify('Новость удалена!')
        },
        [deleteNews.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },

        [saveNewsPopularity.pending]: (state) => {
            state.loading = 'loading';
        },
        [saveNewsPopularity.fulfilled]: (state) => {
            state.loading = 'success'
            notify('Популярность сохранена!')
        },
        [saveNewsPopularity.rejected]: (state, action) => {
            state.loading = 'error'
            notify(action.payload)
        },
    }
});

export const {getSortedNews, removeLoading} = newsSlice.actions;

export default newsSlice.reducer;
