import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {login, toggleModalAuth} from "store/slices/authSlice";
import Form from 'react-bootstrap/Form';
import Loader from "../../@components/loader";
import {EyeIconHide, EyeIconShow} from "assets";
import '../auth.scss'


const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPass, setShowPass] = useState(false)

    const togglePasswordShow = () => setShowPass(!showPass)

    const dispatch = useDispatch()
    const {loginLoading} = useSelector((state) => state.auth)
    const handleSubmit = (e) => {
        e.preventDefault()
        if (loginLoading !== 'loading') {
            dispatch(login({email, password}))
        }
    }

    const openPasswordRecoveryModal = () => {
        dispatch(toggleModalAuth({type: 'recovery', status: true}))
    }

    return (
        <div className="container">
            <Form className="auth-form" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Введите e-mail"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required={true}
                    />
                </Form.Group>
                <Form.Group className="auth-form__password" controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control
                        type={showPass ? 'text' : 'password'}
                        placeholder="Введите пароль"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required={true}
                    />
                    <button type="button" className="auth-form__password-btn" onClick={togglePasswordShow}>{showPass ? <EyeIconShow/> : <EyeIconHide/>}</button>
                </Form.Group>
                <button type="button" className="auth-form__btn-recover mb-3" onClick={openPasswordRecoveryModal}>Восстановить пароль</button>
                <button type="submit" className="auth-form__btn-submit">
                    Войти
                </button>
                <div className="auth-form__bottom">
                    <p>Нет аккаунта? <Link to="/signup">Зарегистрируйтесь</Link></p>
                </div>
            </Form>
            {loginLoading === 'loading' && <Loader fullScreen={true}/>}
        </div>
    );
};

export default Login;