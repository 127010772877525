import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAllSales} from "store/slices/salesSlice";
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { SwiperBtnNext, SwiperBtnPrev } from 'assets';
import TitleSection from 'pages/@components/titleSection';
import ModalPromotions from 'pages/@components/modalPromotions';
import './promotionsSlider.scss';
import Loader from "pages/@components/loader";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

SwiperCore.use([Navigation]);

const PromotionsSlider = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalActiveItem, setModalActiveItem] = useState(null);
  function openModal(item) {
    setModalShow(true);
    setModalActiveItem(item);
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllSales())
  },[])
  const {sales, getAllSalesLoading} = useSelector((state) => state.sales)

  return (
      <>
        {
          getAllSalesLoading === 'loading' ? <Loader withHeight="500"/> : (
              sales.length > 0 && (
                  <div className='section-slider section-slider-promotionsSlider'>
                    <div className='container'>
                      <TitleSection>Акции</TitleSection>
                      <div className='section-slider--mobile__nav'>
                        <SwiperBtnPrev className={`section-slider__btn section-slider__btn--prev`} />
                        <SwiperBtnNext className={`section-slider__btn section-slider__btn--next`} />
                      </div>
                    </div>

                    <div className='section-slider__wrapper'>
                      <SwiperBtnPrev className={`section-slider__btn section-slider__btn--prev`} />
                      <div className='section-slider__swiper'>
                        <Swiper
                            navigation={{
                              nextEl: '.section-slider-promotionsSlider .section-slider__btn--next',
                              prevEl: '.section-slider-promotionsSlider .section-slider__btn--prev',
                            }}
                            breakpoints={{
                              240: { slidesPerView: 1, spaceBetween: 8 },
                              360: { slidesPerView: 1.4, spaceBetween: 8 },
                              420: { slidesPerView: 1.6, spaceBetween: 8 },
                              600: { slidesPerView: 3, spaceBetween: 24 },
                              1024: { slidesPerView: 4, spaceBetween: 24 },
                              1200: { slidesPerView: 5, spaceBetween: 24 },
                            }}
                        >
                          {sales.map(el => (
                              <SwiperSlide key={el?._id}>
                                <div className="promotions__slider-img">
                                  <LazyLoadImage
                                      src={el?.imageUrl}
                                      width={234}
                                      height={236}
                                      alt="Фото"
                                      effect="opacity"
                                      onClick={() => openModal(el)}
                                  />
                                </div>
                              </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                      <SwiperBtnNext className={`section-slider__btn section-slider__btn--next`} />
                    </div>
                    <ModalPromotions show={modalShow} onHide={() => setModalShow(false)} item={modalActiveItem} />
                  </div>
              )
          )
        }
      </>
  );
};

export default PromotionsSlider;
