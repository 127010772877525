import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {axiosClient} from "../../axios/axiosClient";

const initialState = {
  products: [],
  productsPagination: {},
  productsLoading: null,
  popularProducts: [],
  popularProductsPagination: {},
  popularProductsLoading: null,
  productsOnSale: [],
  productsOnSalePagination: {},
  productsOnSaleLoading: null,
  newProducts: [],
  newProductsPagination: {},
  newProductsLoading: null,
  searchProductsResult: [],
  searchProductsPagination: {},
  searchProductsLoading: null,
  categories: [],
  categoriesLoading: null,
  productOnePage: null,
  productOnePageLoading: null,
  categoryBreadcrumbs: [],
};

export const getProducts = createAsyncThunk(
    'products/getProducts',
    async function ({page, sort}, { rejectWithValue }) {
      try {
        const response = await axiosClient.get(`/products?page=${page}&sort=${sort}`)
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

export const getProductsByCategory = createAsyncThunk(
    'products/getProductsByCategory',
    async function ({slug, page, sort}, { rejectWithValue }) {
      let breadcrumbs = []
      let products = []
      try {
        const response = await axiosClient.get(`/categories/breadcrumbs/${slug}`)
        if (response?.data) {
          breadcrumbs = response?.data.map((el) => {
            return {...el, slug: '/catalog' + el.slug}
          })
          const response1 = await axiosClient.get(`/products?category=${response?.data[response?.data.length - 1]?._id}&page=${page}&sort=${sort}`)
          if (response1?.data) {
            products = response1?.data
          }
        }

        return {breadcrumbs, products}
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);


export const getPopularProducts = createAsyncThunk(
  'products/getPopularProducts',
  async function (_, { rejectWithValue }) {
    try {
      const response = await axiosClient.get('/products')
      if (response?.data) {
        return response?.data
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getProductsOnSale = createAsyncThunk(
  'products/getProductsOnSale',
  async function (_, { rejectWithValue }) {
    try {
      const response = await axiosClient.get('/products')
      if (response?.data) {
        return response?.data
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const getNewProducts = createAsyncThunk(
    'products/getNewProducts',
    async function (_, { rejectWithValue }) {
      try {
        const response = await axiosClient.get('/products')
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

export const getCategories = createAsyncThunk(
    'products/getCategories',
    async function (_, { rejectWithValue }) {
      try {
        const response = await axiosClient.get('/categories')
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

export const saveCategoriesTrees = createAsyncThunk(
    'products/saveCategoriesTrees',
    async function ({newTree}, { rejectWithValue }) {
      try {
        const response = await axiosClient.post('/categories/savetree', { newTree })
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

export const getProductById = createAsyncThunk(
    'products/getProductById',
    async function ({id}, { rejectWithValue }) {
      try {
        const response = await axiosClient.get(`/products/${id}`)
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

export const searchProducts = createAsyncThunk(
    'products/searchProducts',
    async function ({query, limit = 10, page = 1, sort}, { rejectWithValue }) {
      try {
        const response = await axiosClient.get(`/products/search?q=${query}&limit=${limit}&page=${page}&sort=${sort}`)
        if (response?.data) {
          return response?.data
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: {
    [getProducts.pending]: (state) => {
      state.productsLoading = 'loading';
    },
    [getProducts.fulfilled]: (state, action) => {
      state.productsLoading = 'success';
      state.products = action.payload.products;
      state.productsPagination = action.payload.pagination;
    },
    [getProducts.rejected]: (state) => {
      state.productsLoading = 'error';
    },
    [getProductsByCategory.pending]: (state) => {
      state.productsLoading = 'loading';
    },
    [getProductsByCategory.fulfilled]: (state, action) => {
      state.productsLoading = 'success';
      state.products = action.payload.products.products;
      state.productsPagination = action.payload.products.pagination;
      state.categoryBreadcrumbs = action.payload.breadcrumbs
    },
    [getProductsByCategory.rejected]: (state) => {
      state.productsLoading = 'error';
    },
    [getPopularProducts.pending]: (state) => {
      state.popularProductsLoading = 'loading';
    },
    [getPopularProducts.fulfilled]: (state, action) => {
      state.popularProductsLoading = 'success';
      state.popularProducts = action.payload.products;
    },
    [getPopularProducts.rejected]: (state) => {
      state.popularProductsLoading = 'error';
    },
    [getProductsOnSale.pending]: (state) => {
      state.productsOnSaleLoading = 'loading';
    },
    [getProductsOnSale.fulfilled]: (state, action) => {
      state.productsOnSaleLoading = 'success';
      state.productsOnSale = action.payload.products;
    },
    [getProductsOnSale.rejected]: (state) => {
      state.productsOnSaleLoading = 'error';
    },
    [getNewProducts.pending]: (state) => {
      state.newProductsLoading = 'loading';
    },
    [getNewProducts.fulfilled]: (state, action) => {
      state.newProductsLoading = 'success';
      state.newProducts = action.payload.products;
    },
    [getNewProducts.rejected]: (state) => {
      state.newProductsLoading = 'error';
    },
    [getCategories.pending]: (state) => {
      state.categoriesLoading = 'loading';
    },
    [getCategories.fulfilled]: (state, action) => {
      state.categoriesLoading = 'success';
      state.categories = action.payload;
    },
    [getCategories.rejected]: (state) => {
      state.categoriesLoading = 'error';
    },
    [saveCategoriesTrees.pending]: (state) => {
      state.categoriesLoading = 'loading';
    },
    [saveCategoriesTrees.fulfilled]: (state, action) => {
      state.categoriesLoading = 'success';
      state.categories = action.payload;
    },
    [saveCategoriesTrees.rejected]: (state) => {
      state.categoriesLoading = 'error';
    },
    [getProductById.pending]: (state) => {
      state.productOnePageLoading = 'loading';
    },
    [getProductById.fulfilled]: (state, action) => {
      state.productOnePageLoading = 'success';
      state.productOnePage = action.payload;
    },
    [getProductById.rejected]: (state) => {
      state.productOnePageLoading = 'error';
    },
    [searchProducts.pending]: (state) => {
      state.searchProductsLoading = 'loading';
    },
    [searchProducts.fulfilled]: (state, action) => {
      state.searchProductsLoading = 'success';
      state.searchProductsResult = action.payload.products;
      state.searchProductsPagination = action.payload.pagination;
    },
    [searchProducts.rejected]: (state) => {
      state.searchProductsLoading = 'error';
    },
  },
});

export const {} = productsSlice.actions;

export default productsSlice.reducer;
