import React, {useState} from 'react';
import {useSearchParams} from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { ArrowIconBlack } from 'assets';
import './customSelect.scss';


const CustomSelect = ({page, setCustomSelect}) => {
  let filterOptions = []
  if (page === 'news') {
    filterOptions = [
      {
        name: 'Сначала новые',
        id: 1,
      },
      {
        name: 'Популярные',
        id: 2,
      },
    ];
  } else {
    filterOptions = [
      {
        name: 'Сначала новые',
        id: 1,
      },
      {
        name: 'Популярные',
        id: 2,
      },
      {
        name: 'Цена по убыванию',
        id: 3,
      },
      {
        name: 'Цена по возрастанию',
        id: 4,
      },
    ];
  }
  const [searchParams] = useSearchParams();
  const [selectValue, setSelectValue] = useState(searchParams.get('sort') || 'Сначала новые');
  const handleSelect = (e) => {
    setSelectValue(e)
    if (setCustomSelect) {
      setCustomSelect(e)
    }
  };

  return (
    <>
      <Dropdown className='custom-select' onSelect={handleSelect}>
        <Dropdown.Toggle id='dropdown-autoclose-true'>
          {selectValue}
          <ArrowIconBlack />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {filterOptions.map((el) => (
            <Dropdown.Item eventKey={el.name} active={el.name === selectValue} key={el.id}>
              {el.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CustomSelect;
