import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart } from 'store/slices/cartSlice';
import { CartIconBtn } from 'assets';
import CustomCounter from '../customCounter';
import './productCard.scss';
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';

const ProductCard = ({ border = false, product }) => {
  const [productCounter, setProductCounter] = useState(0);
  const dispatch = useDispatch();
  const addToCartHandle = () => {
    dispatch(addToCart({ product, productCounter }));
    setProductCounter(0);
  };

  console.log(product)

  return (
    <div className='productCard' style={{ border: border ? '1px solid rgba(0, 0, 0, 0.1)' : '' }}>
      <Link
        to={`/catalog/product/${product._id}`}
        className='productCard-img__wrap'
      >
          <div className="productCard-img">
              <LazyLoadImage
                  src={product.imagePreview}
                  width={178}
                  height={167}
                  alt="Фото продукта"
                  effect="opacity"
              />
          </div>
        {product.promoText && (
          <p
            className='productCard-promo'
            style={{ background: product.isPromotional ? '#e11e26' : '#74BE84' }}
          >
            {product.promoText}
          </p>
        )}
      </Link>
      <div className='productCard-top'>
        {product.available ? (
          <span className='productCard-status' style={{ color: '#74BE84' }}>
            В наличии
          </span>
        ) : (
          <span className='productCard-status' style={{ color: '#e11e26' }}>
            Нет в наличии
          </span>
        )}
        <Link
            to={`/catalog/product/${product._id}`}
            className='productCard-name'
        >
          {product.name}
        </Link>
      </div>
        {
            product.sku ? (
              <span className='productCard-status'>
                {product.sku}
              </span>
            ) : (
              <span className='productCard-status'>
                0
              </span>
            )
        }
        {
            product.isPromotional ? (
                <h4 className='productCard-price productCard-price--red'>
                    {product.price} c {' '}
                    <span className='productCard-price-old'> {product.oldprice} с</span>
                </h4>
            ) : (
                <h4 className='productCard-price'>
                    {product.price} с
                </h4>
            )
        }
      <div className='productCard-bottom'>
        <CustomCounter setProductCounter={setProductCounter} productCounter={productCounter} />
        {product.available && (
          <button className='productCard-btn btn-transitioned-animated' onClick={addToCartHandle}>
            в корзину
            <CartIconBtn />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
