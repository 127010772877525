import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../store/slices/cartSlice';
import Breadcrumbs from '../@components/breadcrumbs';
import { ArrowIconPrev, CartIcon } from 'assets';
import ProductSlider from './components/productSlider';
import ProductCardSlider from '../@components/productCardSlider';
import CustomCounter from '../@components/customCounter';
import './productOnePage.scss';
import {getPopularProducts, getProductById} from "../../store/slices/productsSlice";
import Loader from "../@components/loader";

const ProductOnePage = () => {
  const [productCounter, setProductCounter] = useState(0);
  const { popularProducts, popularProductsLoading, productOnePage, productOnePageLoading } = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const addToCartHandle = () => {
    dispatch(addToCart({ product: productOnePage, productCounter }));
    setProductCounter(0);
  };

  const {id} = useParams()

  useEffect(() => {
    dispatch(getProductById({id}))
  },[id])

  useEffect(() => {
    dispatch(getPopularProducts())
  },[])

  return (
      productOnePageLoading === 'loading' ? (
          <Loader withHeight={300}/>
      ) : (
          productOnePage ? (
              <div className='productPage'>
                <div className='container'>
                  <Breadcrumbs custom={
                    [
                      {slug: '/', name: 'Главная'},
                      {slug: '/catalog', name: 'Каталог'},
                      {slug: `/catalog/product/${productOnePage?._id}`, name: productOnePage?.name},
                    ]
                  }/>
                  <div className='productPage__wrap'>
                    <div className='productPage__slider'>
                      <ProductSlider images={productOnePage?.imageUrl} />
                      {productOnePage.promoText && (
                          <p
                              className='productPage-promo'
                              style={{ background: productOnePage.isPromotional ? '#e11e26' : '#74BE84' }}
                          >
                            {productOnePage.promoText}
                          </p>
                      )}
                    </div>
                    <div className='productPage__content'>
                      <div className='d-inline-block'>
                        <Link to='/catalog' className='productPage__link-back'>
                          <ArrowIconPrev />
                          Назад
                        </Link>
                      </div>
                      <h1 className='productPage-title'>{productOnePage?.name}</h1>
                      <p className='productPage-code'>
                        Артикул: <span>{productOnePage?.sku}</span>
                      </p>
                      <h6 className='productPage-subtitle'>Описание</h6>
                      <p className='productPage-text'>{productOnePage?.description}</p>
                      <CustomCounter setProductCounter={setProductCounter} productCounter={productCounter} />
                      <div className='productPage__content-bottom'>
                        {
                          productOnePage?.isPromotional ? (
                              <p className='productPage-price productPage-price-red'>
                                {productOnePage?.price}с {' '}
                                <span className='productPage-price-old'> {productOnePage.oldprice} с</span>
                              </p>
                          ) : (
                              <p className='productPage-price'>{productOnePage?.price} с</p>
                          )
                        }
                        {productOnePage?.available ? (
                            <p className='productPage-available' style={{ color: '#74BE84' }}>В наличии</p>
                        ) : (
                            <p className='productPage-available' style={{ color: '#e11e26' }}>Нет в наличии</p>
                        )}
                      </div>
                      {
                          productOnePage?.available && (
                              <button type='button' className='productPage-btn-cart' onClick={addToCartHandle}>
                                в корзину <CartIcon />
                              </button>
                          )
                      }
                    </div>
                  </div>
                  <div className='productPage__desc'>
                    <p className='productPage__desc-head'>Характеристики</p>
                    <div className='productPage__desc-list'>
                      {
                        productOnePage?.features.length > 0 ? (
                            productOnePage?.features.forEach((el, ind) => (
                                <div className='productPage__desc-list__item' key={ind}>
                                  <p>{el?.name}</p>
                                  <span>{el?.value}</span>
                                </div>
                            ))
                        ) : (
                            <p>Пусто</p>
                        )
                      }
                    </div>
                  </div>
                </div>
                {
                  popularProductsLoading === 'loading' ? <Loader withHeight={600}/> : (
                      popularProducts.length > 0 && (
                          <ProductCardSlider
                              title={'Вам может быть интересно'}
                              customClass={'section-slider-product-page'}
                              cardBorder={true}
                              products={popularProducts}
                          />
                      )
                  )
                }
              </div>
              ) : (
              <h2 className="productPage-not-found">Товар не найден</h2>
          )

      )
  );
};

export default ProductOnePage;
