import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import ModalAuth from "../modalAuth";

const AuthLayout = () => {
    const {isLoggedIn} = useSelector((state) => state.auth)

    return (
        !isLoggedIn ? (
            <>
                <Outlet/>
                <ModalAuth/>
            </>
        ) : <Navigate to="/profile"/>
    );
};

export default AuthLayout;