import React from 'react';

const ErrorFallback = ({error, resetErrorBoundary}) => {
    return (
        <div className="error">
            <p>Что-то пошло не так</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Попробуйте ещё раз</button>
        </div>
    );
};

export default ErrorFallback;