import React from 'react';
import TitleSection from "../@components/titleSection";
import './termsOfUse.scss'

const TermsOfUse = () => {
    return (
        <div className="terms">
            <div className="container">
                <TitleSection redLine={false}>Пользовательское соглашение</TitleSection>
                <h4 className="terms-subtitle">СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ (ДЛЯ ПОСЕТИТЕЛЯ САЙТА)</h4>
                <p className="terms-text">
                    Настоящим свободно, своей волей и в своем интересе даю согласие Обществу с ограниченной ответственностью «КАНЦТАНТА СЕРВИС» ОГРН 1097746290135, ИНН 7723716712, адрес (место нахождения): 115088, город Москва, улица Угрешская, дом 14, строение 1, офис 206, на автоматизированную и неавтоматизированную обработку моих персональных данных в соответствии со следующим перечнем:
                </p>
                <ul className="terms-list">
                    <li>фамилия, имя и отчество;</li>
                    <li>номер телефона;</li>
                    <li>адрес электронной почты;</li>
                    <li>адрес доставки;</li>
                    <li>источник захода на сайт: https://app.kanzler.kg/ (далее – Сайт) и информация поискового или рекламного запроса;</li>
                    <li>данные о пользовательском устройстве (среди которых разрешение, версия и другие атрибуты, характеризующие пользовательское устройство);</li>
                    <li>пользовательские клики, просмотры страниц, заполнения полей, показы и просмотры баннеров и видео;</li>
                    <li>данные о времени посещения;</li>
                    <li>идентификатор пользователя, хранимый в cookie, для целей повышения осведомленности посетителей Сайта о продуктах и услугах Общества, предоставления релевантной рекламной информации и оптимизации рекламы.</li>
                </ul>
                <p className="terms-text">
                    Настоящее Согласие дается только с целью предоставления ответов на обращения через форму обратной связи на Сайте, оформления и выполнения заказа продукции Общества, а также для информирования о продуктах и услугах Общества.
                    <br/><br/>
                    Согласие предоставляется в том числе на получение от Общества информационных (рекламных) оповещений на электронную почту и телефон, данные по которым предоставлены Обществу.
                    <br/><br/>
                    Общество вправе осуществлять обработку моих персональных данных следующими способами: сбор, запись, систематизация, накопление, хранение, обновление, изменение, использование, передача (распространение, предоставление, доступ). Общество вправе выполнять исключительно автоматизированную обработку моих персональных данных.
                    <br/><br/>
                    Настоящим подтверждаю, что ознакомлен(а) и согласен с Политикой конфиденциальности персональных данных, утвержденной Обществом, и правилами пользования сайтом.
                    <br/><br/>
                    Настоящее согласие вступает в силу с момента моего перехода на Сайт https://app.kanzler.kg и действует в течение сроков, установленных действующим законодательством РФ, Политикой конфиденциальности персональных данных.
                    <br/><br/>
                    Подтверждаю, что все указанные мной данные принадлежат мне лично.
                    <br/><br/>
                    Согласие на обработку персональных данных может быть отозвано Пользователем путем направления письменного заявления в ООО «КАНЦТАНТА СЕРВИС» по следующему адресу: 115088, город Москва, улица Угрешская, дом 14, строение 1, офис 206.
                </p>
            </div>
        </div>
    );
};

export default TermsOfUse;