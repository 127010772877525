import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeItemQuantity, removeItem } from 'store/slices/cartSlice';
import CustomCounter from 'pages/@components/customCounter';

const CustomCartCounter = ({ defaultValue, productId }) => {
  const [productCounter, setProductCounter] = useState(defaultValue);
  const dispatch = useDispatch();
  useEffect(() => {
    if (productCounter !== 0) {
      dispatch(changeItemQuantity({ productId, productCounter }));
    } else {
      dispatch(removeItem(productId));
    }
  }, [productCounter]);

  return <CustomCounter setProductCounter={setProductCounter} productCounter={productCounter} />;
};

export default CustomCartCounter;
