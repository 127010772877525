import React, { useEffect, useState } from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getNewProducts,
  getPopularProducts,
  getProducts,
  getProductsByCategory,
  searchProducts
} from '../../store/slices/productsSlice';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumbs from '../@components/breadcrumbs';
import ProductCard from '../@components/productCard';
import CustomSelect from '../@components/customSelect';
import Loader from '../@components/loader';
import { ArrowIcon, ArrowIconBlack, CrossIcon } from 'assets';
import './catalog.scss';
import Pagination from "../@components/pagination";

const Catalog = ({ title }) => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [catalogType, setCatalogType] = useState('')
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (location.pathname === '/catalog') {
      setCatalogType('catalog')
      dispatch(getProducts({page: searchParams.get('page') || 1, sort: searchParams.get('sort') || 'Сначала новые'}));
    } else if (location.pathname === '/catalog/search-results') {
      setCatalogType('search')
      dispatch(searchProducts({query: searchParams.get('q'), page: searchParams.get('page') || 1, sort: searchParams.get('sort') || 'Сначала новые'}))
    } else if (location.pathname === '/catalog/popular-products') {
      setCatalogType('popular')
      dispatch(getPopularProducts())
    } else if (location.pathname === '/catalog/new') {
      setCatalogType('new')
      dispatch(getNewProducts())
    } else {
      setCatalogType('categories')
      const slug = location.pathname.replace('/catalog', '')
      dispatch(getProductsByCategory({slug, page: searchParams.get('page') || 1, sort: searchParams.get('sort') || 'Сначала новые'}))
    }
  },[location.pathname, location.search])

  const [customSelect, setCustomSelect] = useState(searchParams.get('sort') || 'Сначала новые')

  const navigate = useNavigate();
  useEffect(() => {
    const q = searchParams.get('q') ? `&q=${searchParams.get('q')}` : ''
    const page = searchParams.get('page') ? `&page=${searchParams.get('page')}` : ''
    const sort = customSelect ? `&sort=${customSelect}` : ''
    const search = q + page + sort
    navigate({
      search: search.replace(/&/, '?'),
    });
  },[customSelect])


  const {
    categories,
    categoriesLoading,
    products,
    productsPagination,
    productsLoading,
    popularProducts,
    popularProductsPagination,
    popularProductsLoading,
    newProducts,
    newProductsPagination,
    newProductsLoading,
    searchProductsResult,
    searchProductsPagination,
    searchProductsLoading,
    categoryBreadcrumbs,
  } = useSelector((state) => state.products);


  const [isSidebarShow, setIsSidebarShow] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarShow(!isSidebarShow)
    if (!isSidebarShow) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  };
  const closeSidebar = () => {
    setIsSidebarShow(false)
    document.body.style.overflow = 'visible'
  }

  return (
      <div className='catalog'>
        <div className='container'>
          <div className='catalog-wrap'>
            <div className='catalog-left'>

              {
                catalogType === 'categories' ? (
                    <Breadcrumbs
                        custom={
                          [
                            {slug: '/', name: 'Главная'},
                            {slug: '/catalog', name: 'Каталог'},
                            ...categoryBreadcrumbs
                          ]
                        }
                    />
                ) : (
                    <Breadcrumbs/>
                )
              }
              {
                categoriesLoading === 'loading' ? (
                    <Loader withHeight={300}/>
                ) : (
                    <>
                      <div className={`catalog-filter ${isSidebarShow && 'catalog-filter--show'}`}>
                        <div className='catalog-filter-header'>
                          <p className='catalog-filter-header__title'>Категории</p>
                          <button
                              type='button'
                              className='catalog-filter-header__btn'
                              onClick={closeSidebar}
                          >
                            <CrossIcon />
                          </button>
                        </div>
                        <Accordion alwaysOpen>
                          {categories.map(el => (
                              <Accordion.Item eventKey={el?._id} key={el?._id}>
                                <Accordion.Header>
                                  {el.name} <ArrowIconBlack />
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className='catalog-filter__subCat'>
                                    <Link
                                        to={`/catalog${el?.slug}`}
                                        className='catalog-filter__subCat-link'
                                        onClick={closeSidebar}
                                    >
                                      Посмотреть все товары
                                      <span>
                              ({el?.quantityOfProducts})
                            </span>
                                    </Link>
                                    <Accordion alwaysOpen>
                                      {el?.children && el?.children.map((el1) => (
                                          <Accordion.Item eventKey={el1?._id} key={el1?._id}>
                                            <Accordion.Header>
                                              {el1.name} <ArrowIconBlack />
                                            </Accordion.Header>
                                            <Accordion.Body>
                                              <div className='catalog-filter__subSubCat'>
                                                <Link
                                                    className='catalog-filter__subSubCat-link'
                                                    to={`/catalog${el1?.slug}`}
                                                    onClick={closeSidebar}
                                                >
                                                  Посмотреть все товары
                                                  <span>
                                        ({el1?.quantityOfProducts})
                                      </span>
                                                </Link>
                                                {el1?.children && el1?.children.map(el2 => (
                                                    <Link
                                                        className='catalog-filter__subSubCat-link'
                                                        to={`/catalog${el2?.slug}`}
                                                        key={el2?._id}
                                                        onClick={closeSidebar}
                                                    >
                                                      {
                                                        el2?.name
                                                      }
                                                      <span>
                                            ({el2?.quantityOfProducts})
                                          </span>
                                                    </Link>
                                                ))}
                                              </div>
                                            </Accordion.Body>
                                          </Accordion.Item>
                                      ))}
                                    </Accordion>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                          ))}
                        </Accordion>
                      </div>
                      <div className={`catalog-filter__container ${isSidebarShow && 'catalog-filter__container--show'}`} onClick={closeSidebar}></div>
                    </>
                )
              }
            </div>
            <div className='catalog-right'>
              <div className='catalog-categories'>
                {catalogType === 'categories' && (
                    <div className='catalog-categories__list'>
                      {
                        categoryBreadcrumbs && categoryBreadcrumbs.length > 0 && (
                            categoryBreadcrumbs[categoryBreadcrumbs.length - 1]?.children.map(el => (
                                <Link to={`/catalog${el?.slug}`} key={el?._id}>{el?.title}</Link>
                            ))
                        )
                      }
                    </div>
                )}
              </div>
              <div className='catalog-banner'>
                <h4 className='catalog-banner__title'>
                  Создай новогоднее настроение вместе с kanzler
                </h4>
                <Link className='catalog-banner__link' to='/promotions'>
                  подробнее
                </Link>
              </div>
              <div className='catalog-results'>
                <div className='catalog-results__header'>
                  <div className='catalog-results__left'>
                    <h2 className='catalog-results__title'>{title}</h2>
                    <button
                        type='button'
                        className='catalog-results__btn-categories'
                        onClick={toggleSidebar}
                    >
                      Категории <ArrowIcon />
                    </button>
                  </div>
                  <div className='catalog-results__right'>
                    <p className='catalog-results__count'>{catalogType === 'search' ? searchProductsPagination?.totalProducts : productsPagination?.totalProducts} товара(-ов)</p>
                    <CustomSelect page="catalog" setCustomSelect={setCustomSelect}/>
                  </div>
                </div>
                {productsLoading === 'loading' || catalogType === 'search' && searchProductsLoading === 'loading' ? (
                    <Loader withHeight={250}/>
                ) : (
                    <div className='catalog-results__grid'>
                      {
                        catalogType === 'search' ? (
                            searchProductsResult.map((product) => (
                                <ProductCard key={product._id} active border product={product} />
                            ))
                        ) : (
                            products.map((product) => (
                                <ProductCard key={product._id} active border product={product} />
                            ))
                        )
                      }
                    </div>
                )}
                <Pagination productsPagination={catalogType === 'search' ? searchProductsPagination : productsPagination}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Catalog;
