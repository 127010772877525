import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderCatalogCol = ({ cat, closeCatalog }) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  return (
    <div className='header-catalog__col'>
      <Link
        to={`/catalog${cat?.slug}`}
        className='header-catalog__col-title'
        onClick={closeCatalog}
      >
        {cat?.name}
      </Link>
      <div
        className={`header-catalog__col-list ${showMore ? 'header-catalog__col-list--active' : ''}`}
      >
        {cat?.children && cat?.children.map(el1 => (
          <Link
            className='header-catalog__col-link'
            to={`/catalog${el1?.slug}`}
            key={el1?._id}
            onClick={closeCatalog}
          >
            {el1?.name}
          </Link>
        ))}
      </div>
      {cat?.children && cat?.children.length > 5 && (
        <button type='button' className='header-catalog__col-btn-more' onClick={toggleShowMore}>
          {showMore ? 'Свернуть' : 'Ещё'}
        </button>
      )}
    </div>
  );
};

export default HeaderCatalogCol;
